<template>
  <!--  <h4><span class="fa fa-file-circle-plus mr-2"></span>Nouvel inventaire</h4>-->
  <!--  <CreateBoard></CreateBoard>-->

  <Accordion :activeIndex="[0, 1]" :multiple="true">
    <AccordionTab header="Inventaire mannuel">
      <CreateBoard></CreateBoard>
    </AccordionTab>
    // TODO: Automatisation des nouveaux inventaires
    <!--    <AccordionTab header="Automatisations">-->
    <!--      <DataTable-->
    <!--        ref="dt"-->
    <!--        rowHover-->
    <!--        stripedRows-->
    <!--        v-model:selection="selected"-->
    <!--        class="p-datatable-sm"-->
    <!--        :value="automationList"-->
    <!--        :paginator="true"-->
    <!--        :rows="rows"-->
    <!--        dataKey="id"-->
    <!--        v-model:filters="filters"-->
    <!--        filterDisplay="row"-->
    <!--        :loading="pending"-->
    <!--      >-->
    <!--        <template #empty> Aucune données </template>-->
    <!--        <template #loading> Chargement des données </template>-->
    <!--        <Column-->
    <!--          selectionMode="multiple"-->
    <!--          class="text-center"-->
    <!--          style="width: 48px"-->
    <!--        />-->
    <!--        <Column :showFilterMenu="false" filterField="id" header="Nom">-->
    <!--          <template #body="{ data }">-->
    <!--            {{ data.name }}-->
    <!--            &lt;!&ndash;          {{ docs[data.center].name }}&ndash;&gt;-->
    <!--          </template>-->
    <!--        </Column>-->
    <!--        <Column filterField="condition" header="Automatisation">-->
    <!--          <template #body="{ data }">-->
    <!--            <span class="font-italic text-sm">-->
    <!--              {{ data.condition }}-->
    <!--            </span>-->
    <!--          </template>-->
    <!--        </Column>-->
    <!--        <Column filterField="scope" header="Périmètre">-->
    <!--          <template #body="{ data }">-->
    <!--            <Tag-->
    <!--              class="mr-2"-->
    <!--              :value="scope.label"-->
    <!--              :severity="scope.severity"-->
    <!--              rounded-->
    <!--              v-for="scope in data.scope"-->
    <!--              :key="scope"-->
    <!--            />-->
    <!--          </template>-->
    <!--        </Column>-->

    <!--        <template #footer> n/A </template>-->
    <!--      </DataTable>-->
    <!--    </AccordionTab>-->
  </Accordion>
</template>

<script>
import { defineComponent, ref } from "vue";
import CreateBoard from "./_Create";
import { FilterMatchMode } from "primevue/api";

export default defineComponent({
  components: { CreateBoard },
  setup() {
    const automationList = ref([]);
    const rows = ref(10);
    const pending = ref(false);
    const selected = ref([]);
    const filters = ref({
      name: { value: null, matchMode: FilterMatchMode.CONTAINS },
      period: { value: null, matchMode: FilterMatchMode.DATE_IS },
      center: { value: null, matchMode: FilterMatchMode.CONTAINS },
      status: { value: null, matchMode: FilterMatchMode.IN },
    });

    automationList.value = [
      {
        id: 1,
        name: "Inventaire mensuel",
        condition: "Dernier jour du mois",
        scope: [
          {
            label: "Sodas",
            severity: "info",
          },
          {
            label: "Alcools",
            severity: "warning",
          },
        ],
      },
      {
        id: 2,
        name: "Inventaire flash",
        condition: "Tous les lundi",
        scope: [
          {
            label: "Vins",
            severity: "danger",
          },
        ],
      },
    ];

    return { automationList, rows, pending, selected, filters };
  },
});
</script>

<style lang="scss" scoped></style>
