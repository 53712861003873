<template>
  <form class="p-4" @submit.prevent="onSubmit">
    <div class="flex p-fluid p-field">
      <div class="col-3">
        <label>Etablissements</label>
        <MultiSelect
          v-model="centers"
          :options="docs"
          optionValue="id"
          optionLabel="name"
          placeholder="Tous"
          class="p-column-filter"
          display="chip"
          style="max-width: 400px"
          :filter="true"
        >
          <template #option="slotProps">
            <div class="p-multiselect-representative-option">
              <span>{{ slotProps.option.name }}</span>
            </div>
          </template>
        </MultiSelect>
      </div>
      <div class="col-6">
        <label>Nom</label>
        <InputText
          type="text"
          v-model="name"
          placeholder="Ex: Inventaire liquides"
        ></InputText>
      </div>
      <div class="col-3">
        <label>Période</label>
        <Calendar
          v-model="period"
          :showIcon="true"
          :manualInput="false"
          :showButtonBar="true"
        />
      </div>
    </div>
    <div class="flex justify-content-end">
      <Button
        label="Effacer"
        class="p-button-text p-button-secondary mr-2"
        icon="pi pi-times"
      />
      <Button type="submit" label="Créer" icon="pi pi-plus" iconPos="right" />
    </div>
  </form>
</template>

<script>
import { reactive, toRefs, ref, onBeforeMount } from "vue";
import useDatabase from "../../composables/useDatabase";
import { projectDatabase } from "@/firebase/config";

export default {
  setup() {
    const { getAll } = useDatabase("centers");
    const { docs, error, pending } = getAll();

    const articles = ref([]);
    const easilysArticles = ref([]);
    onBeforeMount(async () => {
      articles.value = (
        await projectDatabase.ref("mercurial/articles").get()
      ).val();

      easilysArticles.value = (
        await projectDatabase.ref("mercurial-easilys/articles").get()
      ).val();
    });

    const input = reactive({
      centers: [],
      period: undefined,
      name: "Inventaire",
    });
    const onSubmit = async () => {
      let updates = {};
      input.centers.forEach((inputCenterId) => {
        let newBoardKey = projectDatabase.ref("boards").push().key;
        const updateObject = {
          name: input.name,
          period: new Date(input.period).toISOString(),
          center: inputCenterId,
          status: "new",
        };

        const inputCenter = docs.value.filter(
          ({ id }) => id === inputCenterId
        )[0];
        const { easilysSiteId } = inputCenter;
        const isEasilys = !!easilysSiteId;

        const articlesData = isEasilys ? easilysArticles : articles;

        // Add easilys data at board level to let front make the update call
        updates[`boards/${newBoardKey}`] = {
          ...updateObject,
          ...(isEasilys && { easilys: { easilysSiteId } }),
        };

        const mercurialArticlesIds = Object.keys(articlesData.value);

        // Delete center rows that are not in the mercuriale
        if (inputCenter.rows) {
          Object.keys(inputCenter.rows).forEach((articleId) => {
            if (!mercurialArticlesIds.includes(articleId)) {
              updates[`centers/${inputCenterId}/rows/${articleId}`] = null;
            }
          });
        }
        // Delete center articles (in zones/areas) that are not in the mercuriale
        Object.entries(inputCenter.zones).forEach(([zoneId, { areas }]) => {
          if (!areas) return;
          Object.entries(areas).forEach(([areaId, { articles }]) => {
            if (!articles) return;
            Object.entries(articles).forEach(([id, { articleId }]) => {
              if (!mercurialArticlesIds.includes(articleId)) {
                updates[
                  `centers/${inputCenterId}/zones/${zoneId}/areas/${areaId}/articles/${id}`
                ] = null;
              }
            });
          });
        });
      });
      try {
        await projectDatabase.ref().update(updates);
      } catch (e) {
        console.log(e);
      } finally {
        input.centers = [];
        input.period = undefined;
        input.name = "Inventaire";
      }
    };

    return {
      docs,
      error,
      pending,
      ...toRefs(input),
      onSubmit,
    };
  },
};
</script>

<style lang="scss" scoped></style>
